import React from 'react';
import Helmet from 'react-helmet';

import ProductsPage from '..';

// Query result goes to this.props.data
export const query = graphql`
  query {
    allWifiLocal {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          images{url,alt}
          thumbs{url,alt}
          slug
        }
      }
    }
  }
`

const WifiPage = (props) => {
  const products = props.data.allWifiLocal.edges.map((p) => p.node);

  return (
    <>
      <Helmet>
        <title>Internet Indonesia</title>
        <link rel="alternate" href="https://wifirepublic.id/products/internetindonesia/" hrefLang="id" />
        <link rel="canonical" href="https://wifirepublic.id/products/internetindonesia/" />
        <meta name="title" content="Internet Indonesia" />
        <meta name="description" content="Sewa/rental Travel Wifi di Luar Negeri Hemat, Mudah dan Cepat. Terkoneksi dilebih dari 140 negara. Sewa Wifi termurah, yang lebih mahal banyak." />
        <meta name="thumbnail" content="https://api.wifirepublicid.com/images/logo.png" />
        <meta name="og:title" content="Internet Indonesia" />
        <meta name="og:type" content="website" />
        <meta name="og:description" content="Sewa/rental Travel Wifi di Luar Negeri Hemat, Mudah dan Cepat. Terkoneksi dilebih dari 140 negara. Sewa Wifi termurah, yang lebih mahal banyak." />
        <meta name="og:image" content="https://api.wifirepublicid.com/images/logo.png" />
        <meta name="og:url" content="https://wifirepublic.id/products/internetindonesia/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Internet Indonesia" />
        <meta name="twitter:description" content="Sewa/rental Travel Wifi di Luar Negeri Hemat, Mudah dan Cepat. Terkoneksi dilebih dari 140 negara. Sewa Wifi termurah, yang lebih mahal banyak." />
        <meta name="twitter:image" content="https://api.wifirepublicid.com/images/logo.png" />
        <meta name="twitter:url" content="https://wifirepublic.id/products/internetindonesia/" />
        <meta name="twitter:site" content="@wifirepublic" />
      </Helmet>
      <ProductsPage
        category='wifi'
        products={products}
      />
    </>
  )
};

export default WifiPage;